// export const Data = {
//     basePath: document.body.dataset.basepath,
//     siteData: {
//         projects: [],
//         journal: [],
//         site: {
//             projectCategories: []
//         }
//     },

//     async fetchSiteData() {
//         const api = `${this.basePath}/everything.json`;
//         const headers = {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//         };

//         try {
//             const response = await fetch(api, {
//                 method: 'GET',
//                 headers: headers
//             });

//             const data = await response.json();
//             if (data.error) {
//                 console.error('Error:', data.error);
//                 return null;
//             } else {
//                 this.siteData.projects = data.projects || [];
//                 this.siteData.journal = data.journal || [];
//                 this.siteData.site.projectCategories = data.site.projectCategories || [];
//                 return data;
//             }
//         } catch (error) {
//             console.error('Fetch error:', error);
//             return null;

//         }
//     },

//     async buildData() {
//         await this.fetchSiteData();
//         console.log('Data built:', this.siteData);
//     },

//     filterProjectsByCategory(categoryName) {
//         if (!Array.isArray(this.siteData.projects)) return [];
//         return this.siteData.projects.filter(project => project.categories.includes(categoryName));
//     },

//     getLimitedProjects(categoryName, limit = 35, columns = 7) {
//         const filteredProjects = this.filterProjectsByCategory(categoryName);
//         const highlightedItems = [];
//         const otherItems = [];
    
//         // Collect highlighted media and other media from each project
//         for (const project of filteredProjects) {
//             const highlightedMedia = project.media.filter(media => media.highlight);
//             const nonHighlightedMedia = project.media.filter(media => !media.highlight);
    
//             highlightedMedia.forEach(media => highlightedItems.push({ ...media, project }));
//             nonHighlightedMedia.forEach(media => otherItems.push({ ...media, project }));
//         }
    
//         // Shuffle items
//         const shuffleArray = (array) => {
//             for (let i = array.length - 1; i > 0; i--) {
//                 const j = Math.floor(Math.random() * (i + 1));
//                 [array[i], array[j]] = [array[j], array[i]];
//             }
//         };
    
//         shuffleArray(highlightedItems);
//         shuffleArray(otherItems);
    
//         const result = [];
//         let count = 0;
//         let highlightedIndex = 0;
//         let otherIndex = 0;
    
//         // Place highlighted items first
//         while (count < limit && highlightedIndex < highlightedItems.length) {
//             const item = highlightedItems[highlightedIndex];
//             result.push(item);
//             count++;
//             highlightedIndex++;
//         }
    
//         // Place other items if limit is not reached
//         while (count < limit && otherIndex < otherItems.length) {
//             const item = otherItems[otherIndex];
//             result.push(item);
//             count++;
//             otherIndex++;
//         }
    
//         // Fill remaining spots incrementally
//         while (count < limit && (highlightedIndex < highlightedItems.length || otherIndex < otherItems.length)) {
//             if (highlightedIndex < highlightedItems.length) {
//                 const item = highlightedItems[highlightedIndex];
//                 result.push(item);
//                 count++;
//                 highlightedIndex++;
//             }
//             if (count >= limit) break;
//             if (otherIndex < otherItems.length) {
//                 const item = otherItems[otherIndex];
//                 result.push(item);
//                 count++;
//                 otherIndex++;
//             }
//         }
    
//         return result.slice(0, limit);
//     }
// };

// export default Data;

export const Data = {
    siteData: siteData, // Use the embedded site data directly

    getInformationPageData() {
        return this.siteData.information;
    },

    getJournalMedia() {
        return this.siteData.journal;
    },

    filterProjectsByCategory(categoryName) {
        if (!Array.isArray(this.siteData.projects)) return [];
        return this.siteData.projects.filter(project => {
            // If it's film, only return projects with video media
            if (categoryName === 'Film') {
                return project.categories.includes(categoryName) && project.media.some(media => media.thumbVideoUrl || media.videoUrl);
            }
            return project.categories.includes(categoryName);
        });
    },
    
    getLimitedProjects(categoryName, limit = 35, columns = 7) {
        const filteredProjects = this.filterProjectsByCategory(categoryName);
        const highlightedItems = [];
        const otherItems = [];
    
        // Check if the screen is small
        const isSmallScreen = window.innerWidth <= 768;
    
        // Collect media from each project
        for (const project of filteredProjects) {
            let highlightedMedia, nonHighlightedMedia;
    
            if (categoryName === 'Film') {
                highlightedMedia = project.media.filter(media => media.highlight && (media.thumbVideoUrl || media.videoUrl));
                nonHighlightedMedia = project.media.filter(media => !media.highlight && (media.thumbVideoUrl || media.videoUrl));
            } else {
                highlightedMedia = project.media.filter(media => media.highlight);
                nonHighlightedMedia = project.media.filter(media => !media.highlight);
            }
    
            if (isSmallScreen) {
                // On small screens, include only one media item per project
                if (highlightedMedia.length > 0) {
                    highlightedItems.push({ ...highlightedMedia[0], project });
                } else if (nonHighlightedMedia.length > 0) {
                    otherItems.push({ ...nonHighlightedMedia[0], project });
                }
            } else {
                // On large screens, include all media items as per the original logic
                highlightedMedia.forEach(media => highlightedItems.push({ ...media, project }));
                nonHighlightedMedia.forEach(media => otherItems.push({ ...media, project }));
            }
        }
    
        // Shuffle items
        const shuffleArray = (array) => {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
        };
    
        shuffleArray(highlightedItems);
        shuffleArray(otherItems);
    
        const result = [];
        let count = 0;
        let highlightedIndex = 0;
        let otherIndex = 0;
    
        // Place highlighted items first
        while (count < limit && highlightedIndex < highlightedItems.length) {
            const item = highlightedItems[highlightedIndex];
            result.push(item);
            count++;
            highlightedIndex++;
        }
    
        // Place other items if limit is not reached
        while (count < limit && otherIndex < otherItems.length) {
            const item = otherItems[otherIndex];
            result.push(item);
            count++;
            otherIndex++;
        }
    
        if (!isSmallScreen) {
            // Fill remaining spots incrementally on large screens
            while (count < limit && (highlightedIndex < highlightedItems.length || otherIndex < otherItems.length)) {
                if (highlightedIndex < highlightedItems.length) {
                    const item = highlightedItems[highlightedIndex];
                    result.push(item);
                    count++;
                    highlightedIndex++;
                }
                if (count >= limit) break;
                if (otherIndex < otherItems.length) {
                    const item = otherItems[otherIndex];
                    result.push(item);
                    count++;
                    otherIndex++;
                }
            }
        }
    
        return result.slice(0, limit);
    }
};

export default Data;