import InformationPage from './InformationPage.js';
import GalleryPage from './GalleryPage.js';
import ProjectsPage from './ProjectsPage.js';
import Data from '../utils/Data.js';
import Packery from 'packery';

const JournalPage = {
    
    pckryInstance: null,
    container: document.querySelector('.journal'),

    initialize() {
        if(document.querySelector('.journal-inner').innerHTML === ''){
            this.render();
        }
        //  else {
        //     this.fadeInJournalThumbs();
        // }
    },

    render() {
        const data = Data.getJournalMedia();
        const container = document.querySelector('.journal');
        const inner = document.querySelector('.journal-inner');

        inner.innerHTML = '';
        inner.style.width = '';
        inner.style.height = '';

        setTimeout(() => {
            const minMargin = 20;
            const maxMargin = 70;
            const minMarginNegative = 50;
            const maxMarginNegative = 60;
    
            // Function to randomly decide if the value should be negative and generate the margin
            const maybeNegate = (minPos, maxPos, minNeg, maxNeg) => {
                if (Math.random() < 0.001) {
                    return -(Math.random() * (maxNeg - minNeg) + minNeg);
                } else {
                    return Math.random() * (maxPos - minPos) + minPos;
                }
            };
    
            // Generate random margins and possibly negate them
            const generateMargins = () => ({
                marginLeft: `${maybeNegate(minMargin, maxMargin, minMarginNegative, maxMarginNegative)}px`,
                marginTop: `${maybeNegate(minMargin, maxMargin, minMarginNegative, maxMarginNegative)}px`,
                marginRight: `${maybeNegate(minMargin, maxMargin, minMarginNegative, maxMarginNegative)}px`,
                marginBottom: `${maybeNegate(minMargin, maxMargin, minMarginNegative, maxMarginNegative)}px`
            });
    
    
            data.forEach((item, index) => {
                let mediaElement;
                let randomWidth;
                let randomHeight;
            
                const margins = generateMargins();
            
                // Loop through media items if media exists
                if (item.media && item.media.length > 0) {
                    item.media.forEach((mediaItem) => {
                        const isVideo = mediaItem.videoUrl ? true : false;
            
                        if (mediaItem.thumbVideoUrl) {
                            if (mediaItem.videoIsPortrait) {
                                randomHeight = this.biasedRandom(250, 400);
                                randomWidth = randomHeight * 0.5625;
                            } else {
                                randomWidth = this.biasedRandom(400, 600);
                                randomHeight = randomWidth * 0.5625;
                            }
            
                            mediaElement = `<video src="${mediaItem.thumbVideoUrl}" autoplay loop muted playsinline></video>`;
                        } else {

                            const min = 200;
                            const max = 500;
                            const ratio = mediaItem.ratio;

                            // Generate random dimensions based on the ratio
                            if (ratio < 1) {
                                // Landscape: width > height
                                randomHeight = Math.floor(Math.random() * (max - min + 1)) + min;
                                randomWidth = randomHeight * ratio;
                            } else {
                                // Portrait: height > width
                                randomWidth = Math.floor(Math.random() * (max - min + 1)) + min;
                                randomHeight = randomWidth * (1 / ratio);
                            }
                            mediaElement = `<img src="${mediaItem.thumb}" alt="${item.title}">`;
                        }
            
                        if (mediaElement !== undefined) {
                            const thumbnailHTML = `
                                <div 
                                data-title="${item.title}" 
                                data-date="${item.date}"
                                data-parallax="${Math.random() * 0.2}"
                                data-ratio="${mediaItem.ratio}"
                                style="width: ${randomWidth}px; height: ${randomHeight}px; margin-left: ${margins.marginLeft}; margin-top: ${margins.marginTop}; margin-right: ${margins.marginRight}; margin-bottom: ${margins.marginBottom};"
                                class="loading-journal-thumbnail journal-thumbnail ${isVideo ? 'thumb-video' : 'thumb-image'}">
                                    <div class="journal-thumbnail-inner">
                                        ${mediaElement}
                                    </div>
                                    <div class="thumbnail-title">
                                        <span>${item.date}</span>
                                    </div>
                                </div>
                            `;
                            inner.innerHTML += thumbnailHTML;
                        }
                    });

                    setTimeout(() => {
            
                        // Initialize Packery after adding all thumbnails
                        if (JournalPage.pckryInstance) {
                            JournalPage.pckryInstance.destroy();
                            JournalPage.pckryInstance = null;
                        }
                
                        // Initialize Packery after adding all thumbnails
                        JournalPage.pckryInstance = new Packery(inner, {
                            itemSelector: '.journal-thumbnail',
                            transformsEnabled: false,
                            transitionsEnabled: false,
                            transitionDuration: 0
                        });
                
                        JournalPage.pckryInstance.layout();
            
                        // Find the furthest right element
                        const allThumbnails = document.querySelectorAll('.journal-thumbnail');
                        let maxRight = 0;
                        allThumbnails.forEach(thumbnail => {
                            const right = thumbnail.offsetLeft + thumbnail.offsetWidth;
                            if (right > maxRight) {
                                maxRight = right;
                            }
                        });

                        inner.style.width = `${maxRight}px`;
                
                        
                
                        this.setupJournalPageListeners(data);

                        this.fadeInJournalThumbs();


                    }, 10);
            
                }
            });
        }, 10);

    },

    fadeInJournalThumbs() {
        const thumbs = document.querySelectorAll('.journal-thumbnail');
        thumbs.forEach((thumb, index) => {
            const img = thumb.querySelector('img');
            const video = thumb.querySelector('video');
            
            if (img) {
                img.addEventListener('load', () => {
                    thumb.classList.remove('loading-journal-thumbnail');
                });
                
                // If the image is already loaded, remove the class immediately
                if (img.complete) {
                    thumb.classList.remove('loading-journal-thumbnail');
                }
            }
    
            if (video) {
                video.addEventListener('canplaythrough', () => {
                    thumb.classList.remove('loading-journal-thumbnail');
                });
                
                // If the video can already play through, remove the class immediately
                if (video.readyState >= 3) {
                    thumb.classList.remove('loading-journal-thumbnail');
                }
            }
    
            // In case there are no images or videos, remove the class immediately
            if (!img && !video) {
                thumb.classList.remove('loading-journal-thumbnail');
            }
        });
    },

    setupJournalPageListeners() {
        let hoverTimeout;
        let hoverTimeoutInternal;
        let mouseX = 0; // Track the horizontal mouse position
    
        function applyParallax() {
            const container = document.querySelector('.journal');
            const scrollTop = container.scrollTop;
            const containerHeight = container.offsetHeight;
            const containerWidth = container.offsetWidth;
            const centerX = containerWidth / 2;
    
            document.querySelectorAll('.journal .journal-thumbnail').forEach(div => {
                const speed = parseFloat(div.dataset.parallax);
                const divRect = div.getBoundingClientRect();
                const containerRect = container.getBoundingClientRect();
                const divTopEdgeRelativeToContainer = divRect.top - containerRect.top;
                const divBottomEdgeRelativeToContainer = divRect.bottom - containerRect.top;
                const isVisible = divBottomEdgeRelativeToContainer > 0 && divTopEdgeRelativeToContainer < containerHeight;
    
                if (isVisible) {
                    const divMid = divTopEdgeRelativeToContainer + (div.offsetHeight / 2);
                    const containerMid = containerHeight / 2;
                    const distanceFromCenterY = divMid - containerMid;
                    const distanceFromCenterX = mouseX - centerX;
                    const yPos = distanceFromCenterY * speed;
                    const xPos = distanceFromCenterX * speed * 0.1; // Adjust the multiplier for horizontal movement
                    // div.style.transform = `translate(${xPos}px, ${yPos}px)`;
                    div.style.transform = `translateY(${yPos}px)`;
                }
            });
        }
    
        function handleScroll() {
            applyParallax();
            requestAnimationFrame(handleScroll);
        }
    
        function handleMouseMove(event) {
            mouseX = event.clientX;
            applyParallax();
        }
    
        // Observer callback to toggle inView data attribute
        function toggleInView(entries) {
            entries.forEach(entry => {
                entry.target.dataset.inView = entry.isIntersecting ? 'true' : 'false';
            });
            applyParallax();
        }
    
        // Initialize Intersection Observer
        const observer = new IntersectionObserver(toggleInView, {
            root: document.querySelector('.journal-container'),
            threshold: 0.1
        });
    
        if(window.innerWidth > 768){
            document.querySelectorAll('.journal-thumbnail').forEach(thumbnailLink => {
                observer.observe(thumbnailLink);
        
                thumbnailLink.addEventListener("mouseenter", function(e) {
                    clearTimeout(hoverTimeout);
                    clearTimeout(hoverTimeoutInternal);
        
                    hoverTimeout = setTimeout(() => {
                        document.body.classList.remove('show-sub-title');
        
                        hoverTimeoutInternal = setTimeout(() => {
                            const journalTitle = thumbnailLink.getAttribute('data-title');
                            const journalDate = thumbnailLink.getAttribute('data-date');
                            const combinedTitle = journalDate;
        
                            document.querySelector('.sub-title').innerHTML = combinedTitle;
                            document.body.classList.add('show-sub-title');
                        }, 200);
                    }, 500);
                });
        
                thumbnailLink.addEventListener("mouseleave", function(e) {
                    clearTimeout(hoverTimeout);
                    clearTimeout(hoverTimeoutInternal);
                    document.body.classList.remove('show-sub-title');
                });
            });
        
            // Listen for mouse movement
            document.addEventListener('mousemove', handleMouseMove);
        
            // Start handling scroll
            requestAnimationFrame(handleScroll);
        }
    },

    biasedRandom(min, max) {
        const range = max - min;
        const mid = (max + min) / 2;
        let rand = Math.random();
    
        // Bias towards the ends (quadratic distribution)
        rand = rand < 0.5 ? Math.pow(rand * 2, 2) / 2 : 1 - Math.pow((1 - rand) * 2, 2) / 2;
    
        return Math.floor(rand * range) + min;
    },

    openJournalPage() {
        document.body.classList.add('journal-open');
        InformationPage.closeInformationPage();
        GalleryPage.closeGalleryPage();
        ProjectsPage.closeFilters();
    },

    closeJournalPage(){
        document.body.classList.remove('journal-open');
        document.body.classList.remove('menu-open');
    }
};

export default JournalPage;