// import ProjectsPage from '../views/ProjectsPage.js';
// import JournalPage from '../views/JournalPage.js';
// import GalleryPage from '../views/GalleryPage.js';
// import InformationPage from '../views/InformationPage.js';
// import Data from './Data.js';
// import { getState, setState } from './State.js';

// const Router = {
//     basePath: document.body.dataset.basepath,

//     initialize() {
//         window.addEventListener('popstate', this.routeBack.bind(this));
//         this.route(true);
//     },

//     navigate(path) {
//         console.log('navigate');
//         history.pushState(getState(), '', `${this.basePath}/${path}`);
//         this.route();
//     },

//     async route(firstRun = false) {
//         console.log('route');
//         const path = window.location.href.replace(this.basePath, '').trim('/');
//         await this.determineView(path, firstRun);
//     },

//     async routeBack(event) {
//         console.log('routeBack');
//         const path = window.location.href.replace(this.basePath, '').trim('/');
//         await this.determineView(path);
//     },

//     async determineView(path, firstRun = false) {
//         console.log('determineView');
//         const [cleanPath] = path.split('?');
//         const [mainPath, subPath] = cleanPath.split('/').filter(Boolean);

//         if (!Data.siteData.projects.length) {
//             await Data.buildData();
//         }

//         // If the path is a project, show the gallery
//         if (mainPath === 'projects' && subPath) {
//             const project = Data.siteData.projects.find(proj => proj.path === `projects/${subPath}`);
//             const mediaId = getState().currentMediaId;
//             let allProjects = getState().allProjects;

//             // If allProjects is not available in the state, use Data.siteData.projects
//             if (!allProjects || allProjects.length === 0) {
//                 allProjects = Data.siteData.projects;
//             }

//             if (project) {
//                 if (firstRun) {
//                     setState({ currentProject: subPath, galleryOpen: true, allProjects: Data.siteData.projects });
//                     history.replaceState(getState(), '', `${this.basePath}/projects/${subPath}`);
//                     ProjectsPage.initialize(getState().currentCategory);
//                 }

//                 // Reorder projects to ensure the current project is first
//                 const projectIndex = allProjects.findIndex(p => p.path === `projects/${subPath}`);
//                 if (projectIndex !== -1) {
//                     const [currentProject] = allProjects.splice(projectIndex, 1);
//                     allProjects.unshift(currentProject);
//                 }

//                 GalleryPage.initialize(allProjects, project, mediaId);
//                 console.log('GalleryPage initialized');
//                 GalleryPage.openGalleryPage();
//                 GalleryPage.updateStateTitle(0); // Ensure the state title is updated
//             }
//         }
//         // If the path is the journal, show the journal
//         else if (mainPath === 'journal') {
//             if (firstRun) {
//                 setState({ journalOpen: true });
//                 history.replaceState(getState(), '', window.location.href);
//                 ProjectsPage.initialize(getState().currentCategory);
//             }
//             JournalPage.initialize(Data.siteData.journal);
//             console.log('JournalPage initialized');
//             JournalPage.openJournalPage();
//         }
//         // If the path is information, show the information page
//         else if (mainPath === 'information') {
//             if (firstRun) {
//                 setState({ informationOpen: true });
//                 history.replaceState(getState(), '', window.location.href);
//                 ProjectsPage.initialize(getState().currentCategory);
//             }
//             InformationPage.initialize();
//             console.log('InformationPage initialized');
//             InformationPage.openInformationPage();
//         }
//         // If there is no path, show the home page
//         else {
//             if (firstRun) {
//                 history.replaceState(getState(), '', window.location.href);
//                 ProjectsPage.initialize(getState().currentCategory);
//                 console.log('Home page initialized');
//             } else {
//                 setState({ currentProject: null, galleryOpen: false, currentMediaId: null });
//                 ProjectsPage.openProjectsPage();
//                 console.log('Home page showing');
//             }
//         }
//     },
// };

// export default Router;

import ProjectsPage from '../views/ProjectsPage.js';
import JournalPage from '../views/JournalPage.js';
import GalleryPage from '../views/GalleryPage.js';
import InformationPage from '../views/InformationPage.js';
import Data from './Data.js';
import { getState, setState } from './State.js';

const Router = {
    basePath: document.body.dataset.basepath,

    initialize() {
        window.addEventListener('popstate', this.routeBack.bind(this));
        this.route(true);
    },

    navigate(path) {
        history.pushState(getState(), '', `${this.basePath}/${path}`);
        this.route();
    },

    route(firstRun = false) {
        const path = window.location.href.replace(this.basePath, '').trim('/');
        this.determineView(path, firstRun);
    },

    routeBack(event) {
        const path = window.location.href.replace(this.basePath, '').trim('/');
        this.determineView(path);
    },

    determineView(path, firstRun = false) {
        const [cleanPath] = path.split('?');
        const [mainPath, subPath] = cleanPath.split('/').filter(Boolean);

        // If the path is a project, show the gallery
        if (mainPath === 'projects' && subPath) {
            const project = Data.siteData.projects.find(proj => proj.path === `projects/${subPath}`);
            const mediaId = getState().currentMediaId;
            let allProjects = getState().allProjects;

            // If allProjects is not available in the state, use Data.siteData.projects
            if (!allProjects || allProjects.length === 0) {
                allProjects = Data.siteData.projects;
            }

            if (project) {
                if (firstRun) {
                    setState({ currentProject: subPath, galleryOpen: true, allProjects: Data.siteData.projects });
                    history.replaceState(getState(), '', `${this.basePath}/projects/${subPath}`);
                    ProjectsPage.initialize(getState().currentCategory);
                }

                // Reorder projects to ensure the current project is first
                const projectIndex = allProjects.findIndex(p => p.path === `projects/${subPath}`);
                if (projectIndex !== -1) {
                    const [currentProject] = allProjects.splice(projectIndex, 1);
                    allProjects.unshift(currentProject);
                }

                GalleryPage.initialize(allProjects, project, mediaId);
                console.log('GalleryPage initialized');
                GalleryPage.openGalleryPage();
                GalleryPage.updateStateTitle(0); // Ensure the state title is updated
            }
        }
        // If the path is the journal, show the journal
        else if (mainPath === 'journal') {
            if (firstRun) {
                setState({ journalOpen: true });
                history.replaceState(getState(), '', window.location.href);
                ProjectsPage.initialize(getState().currentCategory);
            }
            JournalPage.initialize(Data.siteData.journal);
            console.log('JournalPage initialized');
            JournalPage.openJournalPage();
        }
        // If the path is information, show the information page
        else if (mainPath === 'information') {
            if (firstRun) {
                setState({ informationOpen: true });
                history.replaceState(getState(), '', window.location.href);
                ProjectsPage.initialize(getState().currentCategory);
            }
            InformationPage.initialize();
            console.log('InformationPage initialized');
            InformationPage.openInformationPage();
        }
        // If there is no path, show the home page
        else {
            if (firstRun) {
                history.replaceState(getState(), '', window.location.href);
                ProjectsPage.initialize(getState().currentCategory);
                console.log('Home page initialized');
            } else {
                setState({ currentProject: null, galleryOpen: false, currentMediaId: null });
                ProjectsPage.openProjectsPage();
                console.log('Home page showing');
            }
        }
    },
};

export default Router;