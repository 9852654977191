import Router from '../utils/Router.js';
import { getState, setState } from '../utils/State.js';
import InformationPage from './InformationPage.js';
import JournalPage from './JournalPage.js';
import ProjectsPage from './ProjectsPage.js';
import ColorThief from 'colorthief';
import Hammer from 'hammerjs';

const GalleryPage = {
    initialize(allProjects, currentProject, mediaId = null) {
        if (!allProjects || allProjects.length === 0) {
            console.error('No projects provided for GalleryPage');
            return;
        }
        this.allProjects = allProjects;
        this.currentProject = currentProject;
        this.mediaId = mediaId;

        this.reorderMedia();
        this.render();
        this.setupListeners();
        this.loadAdjacentSlides(0); // Load the initial slide and its adjacent slides
        this.updateStateTitle(0); // Update the state title for the initial slide
    },

    reorderMedia() {
        if (this.mediaId) {
            // Reorder the media array within the current project
            const mediaIndex = this.currentProject.media.findIndex(mediaItem => mediaItem.id === this.mediaId);
            if (mediaIndex !== -1) {
                const [mediaItem] = this.currentProject.media.splice(mediaIndex, 1);
                this.currentProject.media.unshift(mediaItem);
            }
        }
    },

    render() {
        const container = document.querySelector('.gallery-inner');
        container.innerHTML = '';
    
        // Flatten all media from all projects into a single array without duplicates
        const mediaSet = new Set();
        const allMedia = [];
    
        this.allProjects.forEach(project => {
            project.media.forEach(mediaItem => {
                const uniqueId = `${project.path}_${mediaItem.id}`;
                if (!mediaSet.has(uniqueId)) {
                    mediaSet.add(uniqueId);
                    allMedia.push({ ...mediaItem, project });
                }
            });
        });
    
        // Generate the HTML for all media items without img and video elements
        container.innerHTML = `
            ${allMedia.map((mediaItem, index) => {
                const isVideo = mediaItem.videoUrl ? true : false;
                return `
                    <div class="gallery-slide ${index === 0 ? 'active-slide' : 'hidden-slide'} ${isVideo ? 'slide-video' : 'slide-image'}" data-title="${mediaItem.project.title}" data-client="${mediaItem.project.client}" data-path="${mediaItem.project.path}" data-src="${isVideo ? mediaItem.videoUrl : mediaItem.large}" data-alt="${mediaItem.project.title || ''}">
                    
                    </div>
                `;
            }).join('')}
            <div class="gallery-controls">
                <button class="prev">Previous</button>
                <button class="close">Close</button>
                <button class="next">Next</button>
            </div>
            <div class="gallery-bg-colour-container"></div>
        `;

        const firstSlide = container.querySelector('.gallery-slide');
        if (firstSlide) {
            const projectClient = firstSlide.getAttribute('data-client');
            const projectTitle = firstSlide.getAttribute('data-title');
            // const combinedTitle = projectClient ? `${projectTitle} <span>for</span> ${projectClient}` : projectTitle;
            const combinedTitle = projectClient ? projectClient : projectTitle;
            document.querySelector('.sub-title').innerHTML = combinedTitle;
        }
    
    },

    setupListeners() {
        document.querySelector('.gallery-controls .prev').addEventListener('click', (event) => {
            event.preventDefault();
            this.showPrevImage();
        });

        document.querySelector('.gallery-controls .next').addEventListener('click', (event) => {
            event.preventDefault();
            this.showNextImage();
        });

        document.querySelector('.gallery-controls .close').addEventListener('click', (event) => {
            event.preventDefault();
            setState({ currentProject: null, galleryOpen: false, currentMediaId: null });
            Router.navigate('');
        });

        document.addEventListener('keydown', (event) => {
            switch (event.key) {
                case 'ArrowLeft':
                    event.preventDefault();
                    this.showPrevImage();
                    break;
                case 'ArrowRight':
                    event.preventDefault();
                    this.showNextImage();
                    break;
                case 'Escape':
                    event.preventDefault();
                    setState({ currentProject: null, galleryOpen: false, currentMediaId: null });
                    Router.navigate('');
                    break;
            }
        });

        document.addEventListener('click', (event) => {
            const gallerySlide = event.target.closest('.gallery-slide');
            if (gallerySlide) {
                const isMediaElement = event.target.tagName === 'IMG' || event.target.tagName === 'VIDEO';
                const isToggleAudioButton = event.target.classList.contains('toggle-audio');

                if (isMediaElement && !isToggleAudioButton) {
                    event.preventDefault();
                    this.showNextImage();
                } else if (!isMediaElement && !isToggleAudioButton && window.innerWidth > 768) {
                    event.preventDefault();
                    setState({ currentProject: null, galleryOpen: false, currentMediaId: null });
                    Router.navigate('');
                }
                
            }
        });

        if (window.innerWidth < 768) {
            const gallery = document.querySelector('.gallery');
        
            if (gallery) {
                const hammer = new Hammer(gallery);
        
                hammer.on('swipeleft', (event) => {
                    this.showNextImage();
                    event.preventDefault();
                });
        
                hammer.on('swiperight', (event) => {
                    this.showPrevImage();
                    event.preventDefault();
                });
            }
        }
    },

    showPrevImage() {
        const container = document.querySelector('.gallery-inner');
        const activeSlide = container.querySelector('.active-slide');
        const currentIndex = Array.from(container.querySelectorAll('.gallery-slide')).indexOf(activeSlide);
        const totalSlides = container.querySelectorAll('.gallery-slide').length;
        const newIndex = (currentIndex === 0) ? totalSlides - 1 : currentIndex - 1;

        this.updateSlide(container, currentIndex, newIndex);
    },

    showNextImage() {
        const container = document.querySelector('.gallery-inner');
        const activeSlide = container.querySelector('.active-slide');
        const currentIndex = Array.from(container.querySelectorAll('.gallery-slide')).indexOf(activeSlide);
        const totalSlides = container.querySelectorAll('.gallery-slide').length;
        const newIndex = (currentIndex === totalSlides - 1) ? 0 : currentIndex + 1;

        this.updateSlide(container, currentIndex, newIndex);
    },

    updateSlide(container, oldIndex, newIndex) {
        const slides = container.querySelectorAll('.gallery-slide');
        
        if (oldIndex < 0 || oldIndex >= slides.length || newIndex < 0 || newIndex >= slides.length) {
            console.error('Invalid slide indices', { oldIndex, newIndex });
            return;
        }
    
        const oldSlide = slides[oldIndex];
        const newSlide = slides[newIndex];
    
        if (!oldSlide || !newSlide) {
            console.error('Slide not found', { oldSlide, newSlide });
            return;
        }

        // Mute the video on the old slide if it is not muted
        const oldVideo = oldSlide.querySelector('video');
        if (oldVideo && !oldVideo.muted) {
            oldVideo.muted = true;
            const toggleAudioButton = oldSlide.querySelector('.toggle-audio');
            if (toggleAudioButton) {
                toggleAudioButton.textContent = 'Sound Off';
            }
        }
    
        oldSlide.classList.remove('active-slide');
        oldSlide.classList.add('hidden-slide');
        newSlide.classList.remove('hidden-slide');
    
        setTimeout(() => {
            newSlide.classList.add('active-slide');
        }, 0);
    
        const existingTitle = document.querySelector('.sub-title').innerHTML;
        const projectClient = newSlide.getAttribute('data-client');
        const projectTitle = newSlide.getAttribute('data-title');
        // const combinedTitle = projectClient ? `${projectTitle} <span>for</span> ${projectClient}` : projectTitle;
        const combinedTitle = projectClient ? projectClient : projectTitle;
    
        if (existingTitle !== combinedTitle) {
            document.querySelector('.sub-title').classList.add('fade-out');
            setTimeout(() => {
                document.querySelector('.sub-title').innerHTML = combinedTitle;
                document.querySelector('.sub-title').classList.remove('fade-out');
            }, 200);
        }

        const hue = newSlide.getAttribute('data-hue');
        const bgContainer = document.querySelector('.bg-colour-container');

        const lightness = (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) ? 12 : 92;

        if (hue) {
            bgContainer.style.backgroundColor = `hsl(${hue}, 30%, ${lightness}%)`;
        } else {
            bgContainer.style.backgroundColor = 'hsl(1, 30%, ' + lightness + '%)';
        }
    
        // Update the browser state with the new project's path
        const newPath = newSlide.getAttribute('data-path');
        history.replaceState(getState(), '', `${Router.basePath}/${newPath}`);
    
        // Load the new active slide and its adjacent slides
        this.loadAdjacentSlides(newIndex);
    
        // Update the state title with the project name and client name
        this.updateStateTitle(newIndex);
    },

    loadAdjacentSlides(currentIndex) {
        const container = document.querySelector('.gallery-inner');
        const slides = container.querySelectorAll('.gallery-slide');
        const totalSlides = slides.length;
    
        const indicesToLoad = [
            currentIndex,
            (currentIndex === 0) ? totalSlides - 1 : currentIndex - 1,
            (currentIndex === totalSlides - 1) ? 0 : currentIndex + 1
        ];
    
        indicesToLoad.forEach(index => {
            const slide = slides[index];
            if (!slide.querySelector('img') && !slide.querySelector('video')) {
                const isVideo = slide.classList.contains('slide-video');
                const src = slide.getAttribute('data-src');
                if (isVideo) {
                    const video = document.createElement('video');
                    video.src = src;
                    video.muted = true;
                    video.loop = true;
                    video.autoplay = true;
                    video.playsInline = true;
                    video.controls = false;
                    slide.appendChild(video);
                    slide.setAttribute('data-hue', '10');

                    // Create and append the toggle audio button
                    const toggleAudioButton = document.createElement('button');
                    toggleAudioButton.className = 'toggle-audio';
                    toggleAudioButton.textContent = 'Sound Off';
                    slide.appendChild(toggleAudioButton);

                    toggleAudioButton.addEventListener('click', () => {
                        video.muted = !video.muted;
                        toggleAudioButton.textContent = video.muted ? 'Sound Off' : 'Sound On';
                    });

                } else {
                    const img = document.createElement('img');
                    img.src = src;
                    img.alt = slide.getAttribute('data-alt');
                    slide.appendChild(img);
                    img.addEventListener('load', function() {
                        const colorThief = new ColorThief();
                        const rgb = colorThief.getColor(img);
                        const hue = ProjectsPage.rgbToHue(rgb[0], rgb[1], rgb[2]);
                        slide.setAttribute('data-hue', hue);
                    });
                    if (img.complete) {
                        img.dispatchEvent(new Event('load'));
                    }
                }
            }
        });
    
        // Unload other slides
        slides.forEach((slide, index) => {
            if (!indicesToLoad.includes(index)) {
                const img = slide.querySelector('img');
                const video = slide.querySelector('video');
    
                if (img) {
                    img.remove();
                }
                if (video) {
                    video.remove();
                }
            }
        });
    },

    updateStateTitle(currentIndex) {
        const container = document.querySelector('.gallery-inner');
        const slides = container.querySelectorAll('.gallery-slide');
        const currentSlide = slides[currentIndex];
        const title = currentSlide.getAttribute('data-title');
        const client = currentSlide.getAttribute('data-client');

        const stateTitle = document.querySelector('.state-title');
        if (stateTitle) {
            stateTitle.innerHTML = `${title}<br>by ${client}`;
        }
    },

    openGalleryPage() {
        ProjectsPage.fadeOutShuffledThumbnails();
        document.body.classList.add('gallery-open');
        InformationPage.closeInformationPage();
        JournalPage.closeJournalPage();
        ProjectsPage.closeFilters();
    },

    closeGalleryPage() {
        document.body.classList.remove('gallery-open');
        const bgContainer = document.querySelector('.bg-colour-container');
        bgContainer.style.backgroundColor = '';
        document.body.classList.remove('menu-open');

        setTimeout(() => {
            document.querySelector('.gallery-inner').innerHTML = '';
            document.body.classList.remove('hide-interface');
        }, 1000);
    },
};

export default GalleryPage;