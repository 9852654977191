const state = {
    informationOpen: false,
    currentCategory: 'Featured',
    journalOpen: false,
    galleryOpen: false,
    currentProject: null,
    currentMediaId: null,
    projectsData: [],
    journalData: [],
    allProjects: [],
};

const listeners = [];

export const getState = () => state;

export const setState = (newState) => {
    Object.assign(state, newState);
    listeners.forEach(listener => listener(state));
};