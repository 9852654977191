import JournalPage from './JournalPage.js';
import GalleryPage from './GalleryPage.js';
import ProjectsPage from './ProjectsPage.js';
import Data from '../utils/Data.js';

const InformationPage = {
    initialize() {
        this.render();
        this.addClickListener();
    },

    render() {
        // const container = document.querySelector('.information');
        // const informationData = Data.getInformationPageData();
        // container.innerHTML = `
        //     <div class="information-inner">
        //         ${informationData.introduction.value}
        //     </div>
        // `;
    },

    openInformationPage() {
        document.body.classList.add('information-open');
        JournalPage.closeJournalPage();
        GalleryPage.closeGalleryPage();
        ProjectsPage.closeFilters();
    },

    addClickListener() {
        const container = document.querySelector('.information');
        container.addEventListener('click', (event) => {
            const inner = container.querySelector('.information-inner');
            if (!inner.contains(event.target)) {
                window.history.back();
            }
        });

        container.addEventListener('click', (event) => {
            if (event.target.tagName === 'A' && event.target.href.startsWith('mailto:') && !event.target.classList.contains('copied')) {
                event.preventDefault();
    
                const email = event.target.href.replace('mailto:', '');
    
                // Copy email to clipboard
                navigator.clipboard.writeText(email).then(() => {
                    event.target.classList.add('copied');
                    const textSpan = document.createElement('span');
                    textSpan.textContent = 'email copied';
                    event.target.style.display = 'none';
                    event.target.textContent = email;
                    event.target.before(textSpan);
    
                    setTimeout(() => {
                        textSpan.innerHTML = 'email on ';
                        event.target.textContent = email;
                        event.target.style.display = 'inline';
                    }, 3000);
                }).catch(err => {
                    console.error('Failed to copy email: ', err);
                });
            }

            if (event.target.tagName === 'A' && event.target.href.startsWith('tel:') && !event.target.classList.contains('copied')) {
                event.preventDefault();
    
                const tel = event.target.href.replace('tel:', '');
    
                // Copy email to clipboard
                navigator.clipboard.writeText(tel).then(() => {
                    event.target.classList.add('copied');
                    const textSpan = document.createElement('span');
                    textSpan.textContent = 'number copied';
                    event.target.style.display = 'none';
                    event.target.textContent = tel;
                    event.target.before(textSpan);
    
                    setTimeout(() => {
                        textSpan.innerHTML = 'phone on ';
                        event.target.textContent = tel;
                        event.target.style.display = 'inline';
                    }, 3000);
                }).catch(err => {
                    console.error('Failed to copy tel: ', err);
                });
            }
        });
    },

    closeInformationPage(){
        document.body.classList.remove('information-open');
        document.body.classList.remove('menu-open');
    }
};

export default InformationPage;